<template>
  <div class="card market-recipe-selection-card">
    <div v-if="withHeader" class="card-header">
      <h3 class="card-title" data-test="label-selection-header">{{caption}} ({{totalSelected}} selected)</h3>
      <div v-if="filter" class="card-options">
        <input
          v-model="query"
          type="text"
          class="form-control"
          placeholder="Filter"/>
      </div>
    </div>
    <div class="market-recipes-container">
      <table class="table table-vcenter text-nowrap card-table">
        <tbody>
          <template v-for="(recipes, key, index) in transformedMarketRecipes">
            <h5 :key="`${key}-${index}-header`">{{key}}</h5>
            <div :key="`${key}-${index}-recipes`">
              <div v-for="item in recipes" :key="item.id">
                <tr>
                  <td :class="{'row-selected': quantity(item.id) > 0}" class="recipe-selector-container">
                    <recipe-selector
                      v-if="!readOnly"
                      :data-test="`market-recipe-selector-${item.id}`"
                      class="recipe-selector"
                      :quantity="quantity(item.id)"
                      :max="max"
                      :disabled="totalSelected >= max"
                      :name="item.name"
                      :number="item.id"
                      :price="item.price"
                      @change="quantity => setRecipeQuantity({recipe_id: item.id, quantity})"
                    />
                    <div v-else class="readonly-container">
                      <p>
                        {{item.id}}. {{item.name}}
                      </p>
                    </div>
                  </td>
                </tr>
              </div>
            </div>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import RecipeSelector from '@/components/shared/recipe-selector/index.vue';

export default {
  name: 'MarketRecipeSelectionCard',
  components: {
    RecipeSelector,
  },
  props: {
    caption: String,
    disabled: {default: () => false, type: Boolean},
    filter: {default: true},
    items: Array,
    max: Number,
    readOnly: Boolean,
    selections: {
      type: Array,
      default: () => [],
    },
    totalSelected: Number,
    withHeader: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      query: '',
    };
  },
  computed: {
    shownItems() {
      return this.items.filter(item => this.query === '' || item.name.split(' ').join('').toLowerCase().includes(this.query.split(' ').join('').toLowerCase()));
    },
    transformedMarketRecipes() {
      const desserts = this.shownItems.filter(recipe => recipe.type === 'dessert').map(this.mapMarketRecipesToGetPrice);
      const breakfasts = this.shownItems.filter(recipe => recipe.type === 'breakfast').map(this.mapMarketRecipesToGetPrice);
      const sides = this.shownItems.filter(recipe => recipe.type === 'side').map(this.mapMarketRecipesToGetPrice);

      return {
        'dessert': desserts,
        'breakfast': breakfasts,
        'side': sides,
      };
    },
    quantity() {
      return id => this.selections.find(selected => selected.recipe_id === id)?.quantity || 0;
    },
  },
  methods: {
    setRecipeQuantity(selection) {
      this.$emit('input', selection);
    },
    mapMarketRecipesToGetPrice(recipe) {
      const price = Number(recipe.unit_price) + Number(recipe.vat);

      return {
        ...recipe,
        price,
      };
    },
  },
};
</script>

<style scoped>
.market-recipe-selection-card {
  max-height: 560px;
}

.market-recipes-container {
  overflow-y: scroll;

  h5 {
    margin: 0;

    padding: 16px 24px;
    border-bottom: 1px solid #e9ecef;
    text-transform: capitalize;
  }
}

.recipe-selector-container {
  padding: 0 !important;

  border-bottom: 1px solid #e9ecef;

  &:hover {
    background-color: #E0E5EC;
  }
}

.recipe-selector {
  >>> .truncate-recipe {
  width: 360px;
  }
}

.row-selected {
  background-color: #d2ecb8;

  &:hover {
    background-color: #d2ecb8;
  }
}

.readonly-container {
  display: flex;

  p {
    margin: 0;
    padding: 8px 20px;
  }
}
</style>
