<template>
  <div class="d-flex align-items-center recipe-selector" :class="{'disabled': disabled && !quantity}">
    <h-stepper
      :value="quantity"
      :max="max"
      :disabled="disabled"
      @input="quantity => $emit('change', quantity)"/>
    <span
      v-b-tooltip.d300.hover
      class="truncate-recipe"
      :class="[{'highlight-swap': highlightSwap}, {'less-width': price || hasSwaps}]"
      :title="name">{{`${number}. ${name}`}}</span>
    <h-badge v-if="hasSwaps" class="swap-badge">
      <img
        v-svg-inline
        data-test="icon-swap-arrow"
        class="swap-arrow"
        src="@/assets/svg/arrow-left-right.svg"
        alt="recipe swap arrow"/>
      swap
    </h-badge>
    <h-badge v-if="price" class="swap-badge">
      {{priceText}}
    </h-badge>
  </div>
</template>

<script>
import HStepper from '@/components/ui/HStepper';
import HBadge from '@/components/ui/HBadge';

export default {
  name: 'RecipeSelector',
  components: {
    HBadge,
    HStepper,
  },
  props: {
    quantity: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: 0,
    },
    hasSwaps: {
      type: Boolean,
      default: false,
    },
    number: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: '',
    },
    availability: {
      type: String,
      default: 'default',
    },
    highlightSwap: {
      type: Boolean,
      default: false,
    },
    price: {
      type: Number,
      default: 0,
    },
    isGourmet: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    priceText() {
      const text = `AED ${this.price}`;
      return this.isGourmet ? `+ ${text}` : text;
    },
  },
};
</script>

<style scoped>
.recipe-selector {
  gap: 5px;
  padding: 8px 20px;
}

.recipe-selector.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.swap-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 6px;
  gap: 2px;

  height: 24px;

  background-color: #E8ECEF !important;
  border: none;
  border-radius: 4px;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #4A5157;
}
.swap-arrow {
  width: 12px;
  height: 12px;
  color: #4A5157;
  fill: #4A5157;
}

.truncate-recipe {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 416px;
}

.less-width {
  width: 368px;
}

.highlight-swap {
  background-color: #E8ECEF;

  margin: 0;
  padding: 0;
}
</style>
