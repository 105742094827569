<template>
  <div>
    <div class="table-responsive">
      <dimmer :active="loading">
        <table class="table table-outline table-vcenter card-table">
          <thead>
            <tr>
              <th v-if="source === REQUEST_SOURCES.Page">Order id</th>
              <th>Customer name</th>
              <th>Delivery date</th>
              <th>Type</th>
              <th>Instructions</th>
              <th>Comment</th>
              <th class="text-center">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td v-if="source === REQUEST_SOURCES.Page" :data-test="'label-order-id-' + item.id">
                <router-link v-if="can(uiPermissions.ORDERS_VIEW)" :to="`/orders/${item.order_id}`">
                  #{{item.order_id}}
                </router-link>
                <span v-else>#{{item.order_id}}</span>
              </td>
              <td :data-test="'label-customer-name-' + item.id">
                {{item.order.customer_name}}
              </td>
              <td :data-test="'label-delivery-date-' + item.id">
                {{item.order.delivery_date}}
              </td>
              <td :data-test="'label-type-' + item.id">
                {{capitalize(lowerCase(item.type))}}
              </td>
              <td :data-test="'label-description-' + item.id" class="white-space-pre-line">
                {{item.description}}
              </td>
              <td :data-test="'label-comment-' + item.id">
                {{item.comment ? item.comment : 'NA'}}
              </td>
              <td>
                <div class="d-flex justify-content-center">
                  <!-- Cases for Dessert Recipes -->
                  <template v-if="item.type === 'add-dessert'">
                    <button
                      v-if="canEditStatus && (item.status === 'created' || item.status === 'pending')"
                      v-b-tooltip="'Mark as cancelled'"
                      :data-test="'btn-cancelled-' + item.id"
                      type="submit"
                      :disabled="item.loading"
                      class="btn btn-danger"
                      @click.prevent="onAction('cancelled', item)">
                      <i v-if="item.loading" class="fe fe-loader spin-icon"></i>
                      <i v-else class="fe fe-x-circle"></i>
                    </button>

                    <div
                      v-else-if="canEditComponentStatus && item.status === 'failed'"
                      v-b-tooltip="'Mark as pending'"
                      :data-test="'btn-failed-' + item.id"
                      class="cursor-pointer"
                      @click.prevent="onAction('pending', item)">
                      <i v-if="item.loading" class="fe fe-loader spin-icon"></i>
                      <div v-else class="d-flex align-items-center">
                        <i class="fe fe-circle rounded-circle mr-1" :class="OrderChangeRequestStatus[item.status].class"/>
                        <span>{{OrderChangeRequestStatus[item.status].label}}</span>
                      </div>
                    </div>

                    <div
                      v-else
                      :data-test="'label-status-' + item.id"
                      style="min-width: 7rem;">
                      <i class="fe fe-circle rounded-circle mr-1" :class="OrderChangeRequestStatus[item.status].class">
                      </i>
                      <span>
                        {{OrderChangeRequestStatus[item.status].label}}
                      </span>
                    </div>
                  </template>

                  <!-- Case for other change request statuses -->
                  <template v-else>
                    <button
                      v-if="canEditStatus && item.status === 'pending'"
                      v-b-tooltip="'Mark as completed'"
                      :data-test="'btn-complete-' + item.id"
                      type="submit"
                      :disabled="item.loading"
                      class="btn btn-success"
                      @click.prevent="onAction('completed', item)">
                      <i v-if="item.loading" class="fe fe-loader spin-icon"></i>
                      <i v-else class="fe fe-check-circle"></i>
                    </button>

                    <div
                      v-else
                      :data-test="'label-status-' + item.id"
                      style="min-width: 7rem;">
                      <i class="fe fe-circle rounded-circle mr-1" :class="OrderChangeRequestStatus[item.status].class">
                      </i>
                      <span>
                        {{OrderChangeRequestStatus[item.status].label}}
                      </span>
                    </div>
                  </template>
                </div>
              </td>
            </tr>
            <tr v-if="items.length === 0">
              <td colspan="100%" class="text-center">
                There are no results matching your criteria.
              </td>
            </tr>
          </tbody>
        </table>
      </dimmer>
    </div>
    <pagination
      :currentPage="page"
      :totalPages="totalPages"
      @goToPage="$emit('goToPage', $event)"></pagination>
  </div>
</template>

<script>
import OrderChangeRequestStatus from '@/assets/enums/OrderChangeRequestStatus';
import Pagination from '@/components/Pagination.vue';
import {orderChangeRequest} from '@/services';
import Permissions from '@hellochef/shared-js/enums/Permissions';

const REQUEST_SOURCES = {
  Page: 'page',
  Component: 'component',
};
export default {
  name: 'OrderChangeRequestsTable',
  components: {
    Pagination,
  },
  props: {
    source: {
      type: String,
      default: REQUEST_SOURCES.Page,
    },
    items: {
      type: Array,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      OrderChangeRequestStatus,
      REQUEST_SOURCES,
    };
  },
  computed: {
    canEditStatus() {
      return this.source === REQUEST_SOURCES.Page &&
        this.can(Permissions.ORDER_CHANGE_REQUESTS_UPDATE);
    },
    canEditComponentStatus() {
      return this.source === REQUEST_SOURCES.Component &&
        this.can(Permissions.ORDER_CHANGE_REQUESTS_UPDATE);
    },
  },
  methods: {
    async onAction(status, item) {
      if (item.loading) {
        return;
      }

      try {
        if (item.type === 'box-cancellation') {
          alert('Box cancellation requests cannot be marked as completed from admin, please complete it via tracman or contact the developer.');
          return;
        }

        if (
          !confirm(`Are you sure you want to mark this request as ${status}?`)
        ) {
          return;
        }
        item.loading = true;
        const {item: changeRequest} = await orderChangeRequest.updateById(
          item.id,
          {
            status,
          },
        );
        this.items.map(it => {
          if (it.id === changeRequest.id) {
            Object.assign(it, changeRequest);
          }
          return it;
        });

        this.$toasted.success('Request status updated successfully.');
      }
      catch (error) {
        console.error(error);
        this.$toasted.error(
          'Error occurred while updating the request status.',
        );
      }
      finally {
        item.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.table thead th,
.text-wrap table thead th {
  vertical-align: middle;
}
</style>
